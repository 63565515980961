import axios from "axios";


export function ApiHelperFunc(query) {
  
  const url = process.env.REACT_APP_AGILE_SERVER_URL;
  
  return  axios.post(url, query, {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
              }
  });
}
