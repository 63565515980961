import React, {Component} from "react";
import {TableHeaderColumn} from "react-bootstrap-table";
import {Button} from "reactstrap"

import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {get} from "lodash";
import Actions from "redux/Actions";
// import PropTypes from "prop-types";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable";

class UserRunTable extends Component {
  constructor(props) {
    super(props)
    this.state ={
      message: "",
      
    }
  }

  bumpRunClick = (row) => {
    const payload = {
      userId: row.userId,
      runId: row.runId
    }

    if (row.runId > 0 ) {
      this.setState({
        message: ""
      })
      this.props.bumpRun(payload)
    } else {
      this.setState({
        message: "Cannot bump this run, it has a 0 runId"
      })

    }

    // this.props.bumpRun(this.props.currentUser.userId, row.runId);
  }

  abandonRunClick = (row) => {
    const payload = {
      userId: row.userId,
      runId: row.runId
    }
    this.props.abandonRun(payload)
  }
  formatActions = (cell, row, formatExtraData, rowIdx) => {
    if (row.runId > 0 ) {
      return (<div><Button
  color="link"
  onClick={() => {
          this.bumpRunClick(row);
        }}
      >
        Bump
                   </Button>
      <Button
  color="link"
  onClick={() => {
          this.abandonRunClick(row);
        }}
      >
        Abandon
      </Button>
              </div>)
    }
      return <span />

  }


  render() {
    var options = {
      noDataText: "Student has no runs in the database"
    }
    return (
      <div>
    <StyledBootstrapTable data={this.props.runs} options={options}>

      <TableHeaderColumn width="15%" dataField="runId">
        Run Id
      </TableHeaderColumn>

      <TableHeaderColumn width="15%" isKey dataField="runSequenceNumber" >
        Seq#
      </TableHeaderColumn>

      <TableHeaderColumn width="30%" dataField="runStatus">
        Run Status
      </TableHeaderColumn>
      <TableHeaderColumn width="40%" tourstop="actionHeader" className="col-last" editable={false} columnClassName="col-last" dataAlign="center" dataFormat={this.formatActions}>
        <div tourstop="actionHeader">Actions</div>
      </TableHeaderColumn>
    </StyledBootstrapTable>

            <footer>
              <p style={{color: "red"}}>{this.state.message}</p>
            </footer>
      </div>
        )
  }
};

const mapStateToProps = createStructuredSelector({
  runs: state => get(state, "users.currentUser.classStudent.studentRuns"),
  currentUser: state => get(state, "users.currentUser")
});

const mapDispatchToProps = dispatch => ({
  abandonRun: (payload) => new Promise((resolve, reject) => dispatch(Actions.abandonRunRequest(payload, resolve, reject))),
  bumpRun: (payload) => new Promise((resolve, reject) => dispatch(Actions.bumpRunRequest(payload, resolve, reject))),
  fetchRuns: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchRunsRequest(userId, resolve,reject))),

});

export default connect(mapStateToProps, mapDispatchToProps)(UserRunTable);
