import { takeLatest, all } from "redux-saga/effects"
import API from "services/Api"
import MBRAPI from "services/MbrApi"


/* ------------- Types ------------- */

import ActionTypes from "redux/ActionTypes"

/* ------------- Sagas ------------- */
// IMPORT_SAGAS
import AuthSaga from "./AuthSaga"
import AdminSaga     from "./AdminSaga"
import ClassroomSaga     from "./ClassroomSaga"
import UserSaga     from "./UserSaga"
import MembershipSaga from "./MembershipSaga"



require("es6-promise").polyfill();

const Sagas = {
  ...AdminSaga,
  ...ClassroomSaga,
  ...UserSaga,
  ...AuthSaga,
  ...MembershipSaga
}

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api =  API.create()
const mbrapi = MBRAPI.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {

  yield all([
    // some sagas only receive an action

    // some sagas receive extra parameters in addition to an action
   takeLatest(ActionTypes.FETCH_ADMIN_USERS_REQUEST, Sagas.makeFetchAdminUsersRequest, api),
   takeLatest(ActionTypes.FETCH_CLASSROOMS_REQUEST, Sagas.makeFetchClassroomsRequest, api),
   takeLatest(ActionTypes.FETCH_USERS_REQUEST, Sagas.makeFetchUsersRequest, api),
   takeLatest(ActionTypes.FETCH_INSTRUCTORS_REQUEST, Sagas.makeFetchInstructorsRequest, api),
   takeLatest(ActionTypes.FETCH_USER_REQUEST, Sagas.makeFetchUserRequest, api),
   takeLatest(ActionTypes.ADD_CLASSROOM_REQUEST, Sagas.makeAddClassroomRequest, api),
   takeLatest(ActionTypes.INACTIVATE_CLASSROOM_REQUEST, Sagas.makeInactivateClassroomRequest, api),
   takeLatest(ActionTypes.RUNNING_CLASSROOM_REQUEST, Sagas.makeRunningClassroomRequest, api),
   takeLatest(ActionTypes.REGISTRATION_CLASSROOM_REQUEST, Sagas.makeRegistrationClassroomRequest, api),
   takeLatest(ActionTypes.ADD_SECONDARY_INSTRUCTOR_REQUEST, Sagas.makeAddSecondaryInstructorRequest, api),
   takeLatest(ActionTypes.REMOVE_SECONDARY_INSTRUCTOR_REQUEST, Sagas.makeRemoveSecondaryInstructorRequest, api),
   takeLatest(ActionTypes.CHANGE_PRIMARY_INSTRUCTOR_REQUEST, Sagas.makeChangePrimaryInstructorRequest, api),

   // takeLatest(ActionTypes.FETCH_MEMBERSHIPUSER_REQUEST, Sagas.makeFetchMembershipUserRequest, mbrapi),
   takeLatest(ActionTypes.UPDATE_EXPIRATION_REQUEST, Sagas.makeUpdateExpirationRequest, api),
   takeLatest(ActionTypes.UPDATE_USER_EMAIL_REQUEST, Sagas.makeUpdateUserEmailRequest, api),
   takeLatest(ActionTypes.LOGIN_REQUEST, Sagas.makeLoginRequest, api),
   takeLatest(ActionTypes.SIGNOUT_REQUEST, Sagas.makeSignoutRequest, api),
   takeLatest(ActionTypes.FETCH_RUNS_REQUEST, Sagas.makeFetchRunsRequest, api),
   takeLatest(ActionTypes.BUMP_RUN_REQUEST, Sagas.makeBumpRunRequest, api),
   takeLatest(ActionTypes.ABANDON_RUN_REQUEST, Sagas.makeAbandonRunRequest, api),
   takeLatest(ActionTypes.FETCH_MEMBERSHIP_USERS_REQUEST, Sagas.makeFetchMembershipUsersRequest, mbrapi),
   takeLatest(ActionTypes.FETCH_MEMBERSHIP_USER_REQUEST, Sagas.makeFetchMembershipUserRequest, mbrapi),
   takeLatest(ActionTypes.UPDATE_EMAIL_REQUEST, Sagas.makeUpdateEmailRequest, mbrapi),
   // takeLatest(ActionTypes.UPDATE_ROLE_REQUEST, Sagas.makeUpdateRoleRequest, mbrapi),
   takeLatest(ActionTypes.CHECK_MBR_USER_STATUS_REQUEST, Sagas.makeCheckMbrUserStatusRequest, mbrapi),
   takeLatest(ActionTypes.ADMIN_RESET_PASSWORD_REQUEST, Sagas.makeAdminResetPasswordRequest, mbrapi),
   takeLatest(ActionTypes.ADD_USER_REQUEST,Sagas.makeAddUserRequest, mbrapi),
   takeLatest(ActionTypes.BULK_ADD_USERS_REQUEST,Sagas.makeBulkAddUsersRequest, mbrapi),
  ])
}
