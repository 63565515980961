import React, { Component, Fragment } from "react";
import Select from "react-select";
import { ApiHelperFunc } from "../../../../services/AgileApi"

  
const statusOptions = [{
		value: 0,
		label: "ACTIVE"
	},
	{
		value: 1,
		label: "INACTIVE"
	},
	{
		value: 2,
		label: "ARCHIVED"
	}];

class EditAgileClassStatus extends Component {
	updateStatus = async (selected) => {
		const UPDATE_CLASSROOM_MUTATION = {
			query: `mutation updateClassroom(
				$id: Int!
        $status: GeneralStatus!
			  ) {
				updateClassroom(
					id:$id, 
					status:$status
				) {
				  id
				}
			  }`,
			variables: {
				id: this.props.currentClassroom.id,
				status: selected.label
			}
		}
	
	return ApiHelperFunc(UPDATE_CLASSROOM_MUTATION).then(response => {
      if (response.data.errors) {
        throw new Error(response.data.errors[0].message)
      }

      })
	}

	render() {
		return (
			<Fragment>
				<span>{this.props.currentClassroom.ClassroomStatus}</span>
				<Select
					id="statusSelect"
					defaultValue={statusOptions.filter(option => option.label === this.props.currentClassroom.classroomStatus)}
					options={statusOptions}
					placeholder="Select status ..."
					onChange={selected => {
						this.updateStatus(selected);
					}}
				/>
			</Fragment>
		);
	}
}


export default EditAgileClassStatus;
