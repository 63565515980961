const routes = {
	"/login": "Login",
	"/": "Users Overview",
	"/user-overview": "User Overview",
	"/agile-user-overview/:id": "Agile-User Overview",
	"/classrooms": "Classrooms Overview",
	"/insructors": "Instructors Overview",
	"/teams": "Teams Overview",
	"/adminUsers": "Admin Users Overview",
  "/reports": "Reports"
  // ,
	// "/organizations": "Business / University Overview",
	// "/organization-overview/:id": "Business / University Overview"
};

export default routes;
