import React, {Component} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Badge} from "reactstrap";
import styled from "styled-components";
import moment from "moment"
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {get} from "lodash";

import Actions from "redux/Actions";



const DetailsList = styled.ul `
	list-style: none;
	padding-left: 0;
	column-count: 2;

	li {
		display: flex;
		align-items: center;
		font-size: 0.75rem;
		margin-bottom: 0.5rem;
	}

	h4 {
		font-size: 1em;
		margin-bottom: 0;
		line-height: 1rem;
		margin-right: 0.5rem;
		font-weight: 600;
	}

	p {
		margin-bottom: 0;
	}
`;

const SubList = styled.ul `
	list-style: none;
	padding-left: 0;
	column-count: 1;

	li {
		display: flex;
		align-items: center;
		font-size: 0.75rem;
		margin-bottom: 0.5rem;
	}

	h4 {
		font-size: 1em;
		margin-bottom: 0;
		line-height: 1rem;
		margin-right: 0.5rem;
		font-weight: 600;
	}

	p {
		margin-bottom: 0;
	}
`;
class UserCard extends Component {
  constructor(props) {
		super(props);
		let isLoading = false
		if (props.currentUser  === undefined || props.requesting === true) {
			isLoading = true
		}
		this.state = {
			loading: isLoading,
		};
  }
  
   
  formatDate = cell => {
    return moment(cell).format("MM/DD/YYYY").toString()
  }

  formatBoolean = cell => {
    return cell === true ? "TRUE" : "FALSE"
  }


  typeFormatter = cell => {
    switch (cell) {
      case 0:
      case "0":
        return <Badge color="primary">student</Badge>;
      case 80:
      case "80":
        return <Badge color="info">instructor as student</Badge>;
      case 89:
      case "89":
        return <Badge color="success">instructor</Badge>;
      case 99:
      case "99":
        return <Badge color="danger">SPL</Badge>;
      default:
        return cell;
    }
  };

  formatClass = () => {
    if (this.props.currentUser !== null && this.props.currentUser !== undefined && this.props.currentUser.classStudent !== null && this.props.currentUser.classStudent !== undefined) {

      if (this.props.currentUser.classStudent.classroomId === undefined) {
        return "Class not set"
      } else {
        return `${this.props.currentUser.classStudent.classroomId} - ${this.props.currentUser.classStudent.classroomName}`
      }
    } else {
      return "Student / Classroom information not available - student may not be registered or may be instructor"

    }
  }
  formatInstructor = () => {
    if (this.props.currentUser !== null && this.props.currentUser !== undefined && this.props.currentUser.classStudent !== null && this.props.currentUser.classStudent !== undefined) {
      if (this.props.currentUser.classStudent.instructorId === undefined) {
        return "Instructor not set"
      } else {
        return `${this.props.currentUser.classStudent.instructorId} - ${this.props.currentUser.classStudent.instructorName}`
      }
    } else {
      return "Instructor not set"
    }
  }

  render() {

    return (
     <Card>
              <CardBody>
                <h3>
                  {
                    this.props.currentUser.user.firstName
                      ? (`${this.props.currentUser.user.firstName} ${this.props.currentUser.user.lastName}`)
                      : (<Badge color="danger">Name incomplete!</Badge>)
                  }
                </h3>
                <DetailsList>
                  <li>
                    <h4>User Id:</h4>
                    <p>{this.props.currentUser.user.userId}</p>
                  </li>
                  <li>
                    <h4>Username:</h4>
                    <p>
                      {
                        this.props.currentUser.user.nickName || (<Badge color="warning">
                          nickName empty</Badge>)
                      }
                    </p>
                  </li>
                  <li>
                    <h4>Email:</h4>
                    <p>
                      {
                        this.props.currentMembershipUser.Email || (<Badge color="warning">
                          userEmail empty</Badge>)
                      }
                    </p>
                  </li>


   <li>
                    <h4>Last Activity Date:</h4>
                    <p>
                      {this.props.currentMembershipUser.LastActivityDate && this.formatDate(this.props.currentMembershipUser.LastActivityDate)}

                    </p>
                    </li>

<li>
                    <h4>Last Login Date:</h4>
                    <p>
                      {this.props.currentMembershipUser.LastLoginDate && this.formatDate(this.props.currentMembershipUser.LastActivityDate)}

                    </p>
                  
                  </li>
                
                 
                  {/* <li>
						<h4>Status:</h4>
						<p>
							{this.props.currentUser.user.status ? (
								<Badge color="danger">{status}</Badge>
							) : (
								<Badge color="warning">status empty</Badge>
							)}
						</p>
					</li> */
                  }
                   <li>
                    <h4>User's login status:</h4>
                    <p>
                      {this.props.currentMembershipUser.LoginStatus}

                    </p>
                    {/* <p>{!this.props.currentUser.user.type && <Badge color="warning">type empty</Badge>}

                    </p> */}
                  </li>    <li>
                    <h4>is Approved?:</h4>
                    <p>
                    {this.formatBoolean(this.props.currentMembershipUser.IsApprove)}

                    </p>
                    {/* <p>{!this.props.currentUser.user.type && <Badge color="warning">type empty</Badge>}

                    </p> */}
                  </li>    <li>
                    <h4>Is Locked Out?:</h4>
                    <p>
                      {this.formatBoolean(this.props.currentMembershipUser.IsLockedOut)}

                    </p>
                    {/* <p>{!this.props.currentUser.user.type && <Badge color="warning">type empty</Badge>}

                    </p> */}
                  </li>
                  <li><span></span></li>
                 
                  <li>
                    <h4>Start Date:</h4>
                    <p>{
                        this.props.currentUser.user.startDate
                          ? this.formatDate(this.props.currentUser.user.startDate)
                          : "🤷"
                      }</p>
                  </li>
                  <li>
                    <h4>Expiration Date:</h4>
                    <p>{
                        this.props.currentUser.user.expirationDate
                          ? this.formatDate(this.props.currentUser.user.expirationDate)
                          : "🤷"
                      }</p>
                  </li>
               

                  <li>
                    <h4>Class:</h4>
                    <p>
                      {this.formatClass()}
                    </p>
                  </li>
                  <li>
                    <h4>Instructor:</h4>
                    <p>
                      {this.formatInstructor()}
                    </p>
                  </li>
                  <li>


                    
<h4>Type:</h4>
<p>
  {this.props.currentUser.user.type && this.typeFormatter(this.props.currentUser.user.type)}

</p>
{/* <p>{!this.props.currentUser.user.type && <Badge color="warning">type empty</Badge>}

</p> */}
</li>


                </DetailsList>
                <SubList>
                <li>
                    <h4>User's Password Question:</h4>
                    <p>
                      {this.props.currentMembershipUser.PasswordQuestion && this.props.currentMembershipUser.PasswordQuestion}

                    </p>
                    {/* <p>{!this.props.currentUser.user.type && <Badge color="warning">type empty</Badge>}

                    </p> */}
                  </li>

                </SubList>
               
              </CardBody>
            </Card>

    )
  };
};

UserCard.propTypes = {

  currentUser: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    nickName: PropTypes.string,
    userEmail: PropTypes.string,
    startDate: PropTypes.string,
    expirationDate: PropTypes.string,
    status: PropTypes.number,
    type: PropTypes.number,
    userClass: PropTypes.string,
    userInstructor: PropTypes.string,
    userProducts: PropTypes.array
  }),
//   {/* "{"UserId":6,"UserName":"perfect","LoginStatus":"success","LoginMessage":"success",
// "Email":"loritillery@gmail.com","Comment":"494","Role":null,"CreationDate":"2010-10-26T11:55:04+00:00",
// "IsApproved":false,"IsLockedOut":false,"IsOnline":false,"LastActivityDate":"2018-08-17T15:55:55+00:00","
// LastLockoutDate":"2018-08-17T15:55:55+00:00","LastLoginDate":"2018-08-17T15:55:55+00:00",
// "LastPasswordChangeDate":"2018-08-17T15:55:55+00:00","PasswordQuestion":"What was the color of your first car?",
// "PasswordAnswer":null}" */}
  currentMembershipUser: PropTypes.shape({
    UserId: PropTypes.number,
    UserName: PropTypes.string,
    LoginStatus: PropTypes.string,
    LoginMessage: PropTypes.string,
    Email: PropTypes.string,
    Comment: PropTypes.string,
    Role: PropTypes.string,
    CreationDate: PropTypes.string,
    LastLockoutDate: PropTypes.string,
    LastActivityDate: PropTypes.string,
    LastLoginDate: PropTypes.string,
    LastPasswordChangeDate: PropTypes.string,
    PasswordQuestion: PropTypes.string,
    IsApproved: PropTypes.bool,
    IsLockedOut: PropTypes.bool,
    IsOnline: PropTypes.bool
  })
};

const mapStateToProps = createStructuredSelector({
  currentUser: state => get(state, "users.currentUser"),
  currentMembershipUser: state => get(state, "membership.currentMembershipUser"),
  error: state => get(state, "user.error"),
  requesting: state => get(state, "users.requesting")
});

const mapDispatchToProps = dispatch => ({
  // setCurrentUser: (user) => new Promise((resolve, reject) => dispatch(Actions.setCurrentUser(user, resolve, reject))),
  fetchUser: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchUserRequest(userId, resolve, reject))),
  fetchMembershipUser: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchMembershipUserRequest(userId, resolve, reject)))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
