import React, { Fragment } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {get} from "lodash";
import Actions from "redux/Actions";
import styled from "styled-components";

// import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import {faSpinnerThird} from "@fortawesome/fontawesome-pro-solid";
import Loading from "../../components/Loading";

import UserCard from "./components/UserCard/UserCard";
import UserActions from "./components/UserActions/UserActions";
// import UserActivityTable from "./components/UserActivityTable/UserActivityTable";
import UserRunTable from "./components/UserRunTable"

// import { sampleUsers } from "../Users/components/UsersTable/UsersTable.stories";
// import { sampleActivity } from "./components/UserActivityTable/UserActivityTable.stories";

const LoadingContainer = styled.div `
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 200px;
	color: black;

	svg {
		margin-right: 1rem;
	}
`;

const User = (props) => (

	<Fragment>
		<Row>
			<Col md="7">
			{props.requesting  === true || props.currentUser.user === undefined || props.currentUser.user === null ?
			<LoadingContainer>
			<Loading />
		 </LoadingContainer>
			:
				<UserCard />}
			</Col>
			<Col>
				<UserActions />
				<p style={{color: "red"}}>{props.error}</p>
			</Col>
		</Row>
		<Row>
			<Col>
				<Card>
					<CardHeader>User Runs</CardHeader>
					<CardBody>
						<UserRunTable />
					</CardBody>
				</Card>
			</Col>
		</Row>
		{/* <Row>
			<Col>
				<Card>
					<CardHeader>User Activity</CardHeader>
					<CardBody>
						<UserActivityTable activityData={sampleActivity} />
					</CardBody>
				</Card>
			</Col>
		</Row> */}
	</Fragment>
);


const mapStateToProps = createStructuredSelector({
  currentUser: state => get(state, "users.currentUser"),
	error: state => get(state, "user.error"),
	requesting: state => get(state, "users.requesting"),
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => new Promise((resolve, reject) => dispatch(Actions.fetchUsersRequest(resolve, reject))),
  // setCurrentUser: (user) => new Promise((resolve, reject) => dispatch(Actions.setCurrentUser(user, resolve, reject))),
  fetchUser: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchUserRequest(userId, resolve, reject))),
  fetchMembershipUser: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchMembershipUserRequest(userId, resolve, reject)))
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
