import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import requiresAuth from "app/RequiresAuth";

// Import Main styles
// font-awesome icons
import "font-awesome/css/font-awesome.min.css";
// Bootstrap 4.0.0 beta with custom and shared styles
import "static/styles/main.css";
import Login from "routes/Login/Login";
import NotFound from "routes/NotFound";

import App from "./App";
import history from "./store/history";

const Root = ({ store }) => (
	<Provider store={store}>
		<Router history={history} basename="/" >
			<Switch>
				<Route exact path="/login"  name="Login" component={Login} />
        {/* <Route exact path={`${process.env.PUBLIC_URL}/404`} name="404 Not Found" component={NotFound} /> */}
        <Route exact path="/404" name="404 Not Found" component={NotFound} />
				<Route path="/" name="Admin Panel" component={requiresAuth(App)} />
			</Switch>
		</Router>
	</Provider>
);

export default Root;
