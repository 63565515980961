import { call, put } from "redux-saga/effects"
import get from "lodash/get"
import { selectToken } from "utilities/tokenManager"
import Actions from "redux/Actions"
import { parseError } from "./Shared"
import adminUsersData from "./__tests__/data/adminUserTestData"

// change this variable to true to use test data
const useTestData = true


function * makeFetchAdminUsersRequest (api, action) {
    const { resolve, reject } = action
    const token = yield call(selectToken)

   if (useTestData) {
     yield put(Actions.fetchAdminUsersSuccess(adminUsersData))
     return resolve()
   }

     const response = yield call(api.fetchAdminUsersRequest, token)

     if (response.ok) {
       const adminUsers = get(response, "data.data")
       yield put(Actions.fetchAdminUsersSuccess(adminUsers))
       return resolve()
     }
       const error = parseError(response)
       yield put(Actions.fetchAdminUsersFailure(error))
       return reject(error)


  }


// ADD_SAGA_ACTION

export default {
    makeFetchAdminUsersRequest,
    // EXPORT_SAGA_ACTION
  }
