import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Actions from "redux/Actions";
import Users from "routes/Users/Users";
import User from "routes/User/User";
import AgileUser from "routes/AgileUser/AgileUser";
import Classrooms from "routes/Classrooms/Classrooms";
import AdminUsers from "routes/AdminUsers";
import Login from "routes/Login/Login";
import Reports from "routes/Reports/Reports";
import { AgileContextProvider } from "./store/GlobalState";

// import Teams from "routes/Teams/Teams";

import Sidebar from "components/Sidebar/Sidebar";
import Header from "components/Header/Header";
import PageHeader from "components/PageHeader/PageHeader";
import Footer from "components/Footer/Footer";

const App = (props) => {
  return (
    <div className="app">
      <Header signout={props.signout} />
      <div className="app-body">
        {props.authenticated && <Sidebar />}
        <main className="main">
          {props.authenticated && <PageHeader />}
          <AgileContextProvider>
            <Container fluid>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login"
                  component={Login}
                />
                <Route
                  exact
                  path="/"
                  name="Users Overview"
                  render={routeProps => (
                        <Users
                          {...routeProps}
                        />
                      )}
                />

                <Route
                  path="/user-overview"
                  name="User Overview"
                  component={User}
                />
                <Route
                  path="/agile-user-overview/:id"
                  name="Agile User Overview"
                  render={(props) => <AgileUser id={props.match.params.id} {...props} /> } 
                />
                {/* <Route
                  path="/organization-overview/:id"
                  name="Business / University Overview"
                  component={AgileClassrooms}
                /> */}
                {/* <Route
                  exact
                  path="/instructors"
                  name="Instructors Overview"
                  component={Instructors}
                /> */}
                <Route
                  exact
                  path="/classrooms"
                  name="Classrooms Overview"
                  component={Classrooms}
                />
                {/* <Route
                  exact
                  path="/organizations"
                  name="Organizations Overview"
                  component={Organizations}
                /> */}
                <Route
                  exact
                  path="/adminUsers"
                  name="Admin Users Overview"
                  component={AdminUsers}
                />
                <Route
                  exact
                  path="/reports"
                  name="Reports"
                  component={Reports}
                />
              </Switch>
            </Container>
          </AgileContextProvider>
        </main>
      </div>
      <Footer />
    </div>
  )
}


const mapStateToProps = (state) => ({
  location: state.location,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  signout: () =>
    new Promise((resolve, reject) => {
      dispatch(Actions.signoutRequest(resolve, reject))
    }
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
