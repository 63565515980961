import React, { Component, Fragment } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import styled from "styled-components";
import Select from "react-select";
import { ApiHelperFunc } from "../../../../services/AgileApi";
import moment from "moment";

import "static/styles/reactDatepicker.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: end;

  .form-group {
    margin-bottom: 0;
  }
`;
class CreateAgileClassroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryInstructor: {},
      secondaryInstructor: {},
      disableSaveButton: true,
      formStatus: "Ready to add",
      instructors: this.props.instructors,
      paginate: true,
      selectValue: "",
      instructorModal: false,
      isOpen: false,
      textvalue: "",
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      disableDatepicker: true,
    };
  }

  setValue(e) {
    this.setState({
      textvalue: e.target.value,
    });
  }
  action = () => {
    this.setState({
      instructorModal: true,
    });
  };
  toggleInstructorModal = () => {
    this.setState({
      instructorModal: !this.state.instructorModal,
      modalMessage: "",
    });
  };

  handlePrimaryChange = (selected) => {
    if (selected === undefined || selected.length < 1) {
      this.setState({ disableSaveButton: true });
      return;
    }
    this.setState({ primaryInstructor: selected, disableSaveButton: false });
  };

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  };

  changeEndDate = (date) => {

    this.setState({ endDate: date });
  };

  handleCreateClassroom = () => {
    if (
      this.state.startDate === undefined ||
      this.state.startDate === null ||
      this.state.startDate === ""
    ) {
      this.setState({
        disableSaveButton: true,
        formStatus: "Select a start date",
      });
      return;
    }
    if (
      this.state.endDate === undefined ||
      this.state.endDate === null ||
      this.state.endDate === ""
    ) {
      this.setState({
        disableSaveButton: true,
        formStatus: "Select a end date",
      });
      return;
    }
    if (this.state.endDate < this.state.startDate) {
      this.setState({
        disableSaveButton: true,
        formStatus: "End date cannot before start date",
      });
      return;
    }
    if (
      this.state.primaryInstructor === undefined ||
      this.state.primaryInstructor === null ||
      this.state.primaryInstructor.value === undefined ||
      this.state.primaryInstructor.value === null
    ) {
      this.setState({
        disableSaveButton: true,
        formStatus: "Please choose an instructor ...",
      });
      return;
    }

    this.setState({
      disableSaveButton: true,
      formStatus: "Adding ...",
      disableDatepicker: true,
    });
   
 const start = new Date(this.state.startDate).toISOString().split('T')[0];
   const end = new Date(this.state.endDate).toISOString().split('T')[0];
   
    
    const primaryId = Number(this.state.primaryInstructor.value);
    const CREATE_CLASSROOM = {
      query: `mutation createClassroom(
		$productNumber: Int!
		$className: String!
		$status: GeneralStatus!
		$primaryInstructorId: Int!
		$startDate: DateTime
    	$endDate: DateTime
	  ) {
		createClassroom(
		  productNumber: $productNumber
		  className: $className	
		  status: $status
		  primaryInstructorId: $primaryInstructorId		
		  startDate: $startDate
      endDate: $endDate
		) {
		  id
		}
	  }`,
      variables: {
        productNumber: 45,
        className: this.className.value,
        status: "ACTIVE",
        primaryInstructorId: primaryId,
        startDate: start, 
        endDate: end
      },
    };

    return ApiHelperFunc(CREATE_CLASSROOM).then((response) => {
      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }
      this.props.toggleClose();
      this.setState({
        formStatus: "Ready to add",
        disableSaveButton: true,
      });
    });
  };

  render() {
    return (
      <Fragment>
        <Card>
          <CardHeader>Status: {this.state.formStatus} </CardHeader>
          <CardBody>
            <StyledForm>
              <FormGroup>
                <Label for="className">Class Name</Label>
                <Input
                  required
                  type="text"
                  id="className"
                  innerRef={(cn) => (this.className = cn)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="classroomStatus">Status</Label>
                <Input
                  type="select"
                  innerRef={(cs) => (this.classroomStatus = cs)}
                  id="classroomStatus">
                  <option value="ACTIVE" default="default">
                    ACTIVE
                  </option>
                  <option value="ARCHIVED">ARCHIVED</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="startDate">Start Date</Label>
                <div className="customDatePickerWidth">
                  <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={this.changeStartDate}
                    placeholder={
                      this.state.startDate
                        ? `${formatDate(this.state.startDate)}`
                        : "Choose start date"
                    }
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <Label for="endDate">End Date</Label>
                <div className="customDatePickerWidth">
                  <DayPickerInput
                    disabled={this.state.disableDatepicker}
                  		formatDate={formatDate}
									parseDate={parseDate}
                    onDayChange={this.changeEndDate}
                    placeholder={
                      this.state.endDate
                        ? `${formatDate(this.state.endDate)}`
                        : "Select end date"
                    }
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="primaryInstructor">Primary Instructor</Label>

                <Select
                  options={this.props.instructors}
                  placeholder="Choose an instructor ..."
                  onChange={(selected) => {
                    this.handlePrimaryChange(selected);
                  }}
                />
              </FormGroup>

              <Button
                color="primary"
                disabled={this.state.disableSaveButton}
                onClick={this.handleCreateClassroom}>
                Save
              </Button>
            </StyledForm>
          </CardBody>
          <CardFooter>
            Notes: <p>Runs per student: 3</p>
            <p>Product: Agile Simulation </p>
          </CardFooter>
        </Card>
      </Fragment>
    );
  }
}

export default CreateAgileClassroom;
