import React, { Component } from "react";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";

const StyledUserActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  grid-gap: 10px;

  .input-group-addon {
    padding: 0;
  }

  .buttons-row {
    display: flex;
    justify-content: space-between;
  }
`;
// addonType="append"
class UserActions extends Component {
  state = {
    expirDays: 0,
    expirDate: moment().format(),
    email: "",
    user: this.props.user,
    message: "",
  };

  componentDidMount() {
  
    if (
      this.props.user &&
      this.props.user.licenses &&
      this.props.user.licenses.length > 0
    ) {
      this.setState({
        expirDate: this.props.user.licenses[0].expirationDate
      })
   
    }
  }
  componentDidUpdate() {
    setTimeout(
      function () {
        //Start the timer
        this.setState({ message: "" }); //After 5 seconds, message should disappear
      }.bind(this),
      5000
    );
  }
  changeExpirDays = (evt) => {
    this.setState({ expirDays: evt.target.value });
  };
  changeExpirDate = (date) => {
    this.setState({ expirDate: date });
  };

  changeEmail = (evt) => {
    this.setState({ email: evt.target.value });
  };

  updateExpirationDateByDays = async () => {
    this.setState({ message: "Updating..." });

    const expirDate = moment(this.props.user.licenses[0].licenseExpirationDate);

    const newExpirDt = expirDate.add(this.state.expirDays, "days");
    //const userId = this.props.currentUser.userId
    const payload = {
      id: this.props.user.licenses[0].id,
      expirationDate: moment(newExpirDt).toDate(),
    };

    await this.props.updateLicense(payload);
    this.setState({
      expirDays: 0,
      message: "Done",
    });
  };

  updateExpirationDateByDate = async () => {
    this.setState({ message: "Updating..." });

    const payload = {
      userId: this.props.user.id,
      id: this.props.user.licenses[0].id,
      expirationDate: moment(this.state.expirDate, "YYYY-MM-DD"),
    };
    await this.props.updateLicense(payload);
    this.setState({ message: "Done" });
  };

  // updates membership email and mastercontrol user email
  updateEmail = async () => {
    const id = this.props.user.id;
    const email = this.email.value;
    this.setState({ message: "Updating..." });
    /* change membership email - main  email */
    const payload = {
      id: id,
      email: email
    };

    await this.props.updateUser(payload);
    this.setState({
      email: "",
      message: "Done",
    });
  };

  resendEmail = async (evt) => {
    evt.preventDefault();
    this.setState({ message: "Sending..." });
    const response = await this.props.resendVerificationEmail({
      id: this.props.user.id,
    });
    if (response && response.status && response.status.message === "OK") {
      this.setState({ message: "Sent" });
    } else {
      this.setState({ message: response.status.message });
    }
  };

  render() {
    return (
          <Card>
              <CardHeader>
              <strong>Actions</strong>
               {this.state.message && this.state.message !== "" && (
              <div style={{ float: "right" }}>
                <h6 style={{ color: "maroon" }}>{this.state.message}</h6>
              </div>
            )}
              </CardHeader>
              <CardBody>
      <StyledUserActions>
        <Row>
          <Col>
           
          </Col>
        </Row>
        <InputGroup>
          <Button color="primary" onClick={(evt) => this.resendEmail(evt)}>
            Resend Verification Email
          </Button>
        </InputGroup>
        <InputGroup>
          <Label for="expirDays">
            <strong>Add days:</strong>
          </Label>

          <Input
            id="expirDays"
            type="number"
            value={this.state.expirDays}
            onChange={(evt) => this.changeExpirDays(evt)}
            placeholder="days from today"
          />
          <InputGroupAddon addonType="prepend">
            <Button color="primary" onClick={this.updateExpirationDateByDays}>
              Extend License
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <InputGroup>
          <Label for="expirDate">
            <strong>New Expiration:</strong>
          </Label>

          <DayPickerInput
            formatDate={formatDate}
            style={{ marginLeft: "5px" }}
            parseDate={parseDate}
            onDayChange={this.changeExpirDate}
            placeholder={`${formatDate(this.state.expirDate)}`}
          />
          <InputGroupAddon addonType="prepend">
            <Button color="primary" onClick={this.updateExpirationDateByDate}>
              Change Expiration Date
            </Button>
          </InputGroupAddon>
        </InputGroup>

        <InputGroup>
          <Label for="userEmail">
            <strong>New email:</strong>
          </Label>
          <Input
            type="email"
            id="userEmail"
            style={{ marginLeft: "5px" }}
            innerRef={(em) => (this.email = em)}
            value={this.state.email || ""}
            onChange={(evt) => this.changeEmail(evt)}
          />
          <InputGroupAddon addonType="prepend">
            <Button color="primary" onClick={() => this.updateEmail()}>
              Update email address
            </Button>
          </InputGroupAddon>
        </InputGroup>

        {/* <Button color="danger">Close Account</Button> */}
          </StyledUserActions>
        </CardBody>
        </Card>
    );
  }
}

export default UserActions;
