import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonToolbar
} from "reactstrap";
import moment from "moment";
import CreateAgileClassroom from "./CreateAgileClassroom";
import AgileClassActionPopover from "./ClassroomActionPopoverAgile";
import EditPrimaryInstructorAgile from "./EditPrimaryInstructorAgile";
import AddSecondaryInstructorAgile from "./AddSecondaryInstructorAgile";
import EditAgileClassName from "./EditAgileClassName";
import EditAgileClassStatus from "./EditAgileClassStatus";
import EditAgileClassDates from "./EditAgileClassDates";
import { ApiHelperFunc } from "../../../../services/AgileApi";
import ImportAgileUsers from "../../../Users/components/ImportUsers/ImportAgileUsers";
import { CLASSROOMS_QUERY, INSTRUCTORS_QUERY } from "../../../../app/graphql/queries";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable.js";
import StyledTableHeaderRow from "../../../../components/styled/StyledTableHeaderRow.js";


class AgileClassroomTable extends Component {
  state = {
    loading: true,
    classroomList: [],
    instructorList: [],
    organizationList: [],
    instructorModal: false,
    createClassModal: false,
    whichModal: "",
    isOpen: false,
    currentRow: {},
    popoverOpen: false,
    selectedInstructor: {},
    disableSaveButton: true,
    modalAction: "",
    sortName: "classroomId",
    sortOrder: "asc",
    modalMessage: "",
  };

  componentDidMount() {
    this.refreshAll();
  }

  refreshAll = () => {
    Promise.all([
      this.refreshInstructorList(),
      this.refreshClassList()
    ])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(`Error in promises ${error}`);
      });
  };
  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sortName,
      sortOrder,
    });
  };

  statusFormatter = (cell) => {
    const cellValue = cell || "";
    switch (cellValue) {
      case "ACTIVE":
        return <Badge color="success">{cellValue}</Badge>;
      case "INACTIVE":
        return <Badge color="dark">{cellValue}</Badge>;
      case "ARCHIVED":
        return <Badge color="info">{cellValue}</Badge>;
      default:
        return cellValue;
    }
  };

  menuFormatter = (cell, row) => {
    if (Number.parseInt(row.classroomId, 10) === Number.parseInt(0, 10)) {
      return <span />;
    }
    return (
      <AgileClassActionPopover
        row={row}
        id={row.id}
        placement={"left"}
        actionClicked={this.actionItemClicked}
      />
    );
  };

  countFormatter = (cell) => {
    return cell.length;
  };

  primaryInstructorFormatter = (cell) => {
    // cell is an array of instructors
    // If there's no value, return nothing
    if (!cell || cell.length < 1) {
      return "NO PRIMARY INSTRUCTOR";
    }
    let primary = cell.find(c => c.type === "PRIMARY");
    if (primary && primary.user) {
      return primary.user.firstName + " " + primary.user.lastName;
    }
    return "NO PRIMARY INSTRUCTOR";
    
  };

  secondaryInstructorFormatter = (cell) => {
    // cell is an array of instructors
    // If there's no value, return ""
    if (!cell || cell.length < 1) {
      return "";
    }
    let secondary = cell.find(c => c.type === "SECONDARY");
    if (secondary && secondary.user !== null) {
      return secondary.user.firstName + " " + secondary.user.lastName;
    }
    
    return "";
  };
  onRowClick = (row) => {
    this.setState({
      currentRow: row,
    });
  };
  organizationFormatter = (cell) => {
    if (!cell) {
      return "NO Business / University";
    }
    return cell.organizationName;
  };
  renderModalHeader = () => {
    if (this.state.modalAction === "editPrimary") {
      return (
        <Row>
          <Col sm="12">Change Primary Instructor</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "addSecondary") {
      return (
        <Row>
          <Col sm="12">Add Secondary Instructor</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "editClassName") {
      return (
        <Row>
          <Col sm="12">Change Class Name</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "editClassStatus") {
      return (
        <Row>
          <Col sm="12">Change Class Status</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "editClassDates") {
      return (
        <Row>
          <Col sm="12">Change Class Dates</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "importUsers") {
      return (
        <Row>
          <Col sm="12">Import Users</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else {
      return <div>unknown action</div>;
    }
  };

  renderModalBody = () => {
    if (this.state.modalAction === "editPrimary") {
      return (
        <EditPrimaryInstructorAgile
          instructors={this.state.instructorList}
          currentClassroom={this.state.currentRow}
        />
      );
    } else if (this.state.modalAction === "addSecondary") {
      return (
        <AddSecondaryInstructorAgile
          instructors={this.state.instructorList}
          currentClassroom={this.state.currentRow}
        />
      );
    } else if (this.state.modalAction === "editClassName") {
      return (
        <EditAgileClassName
          currentClassroom={this.state.currentRow}
          refresh={this.refreshClassList}
        />
      );
    } else if (this.state.modalAction === "editClassStatus") {
      return (
        <EditAgileClassStatus
          currentClassroom={this.state.currentRow}
          refresh={this.refreshClassList}
        />
      );
    } else if (this.state.modalAction === "editClassDates") {
      return (
        <EditAgileClassDates
          currentClassroom={this.state.currentRow}
          refresh={this.refreshClassList}
        />
      );
    } else {
      return <ImportAgileUsers currentClassroom={this.state.currentRow} />;
    }
  };

  toggleInstructorModal = () => {
    this.setState({
      instructorModal: !this.state.instructorModal,
      modalMessage: "",
    });
  };

  toggleDropdown = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  async refreshClassList() {
    await ApiHelperFunc(CLASSROOMS_QUERY)
      .then((res) => {
        this.setState({ classroomList: res.data.data.classrooms });
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async refreshInstructorList() {
    await ApiHelperFunc(INSTRUCTORS_QUERY)
      .then((res) => {
        const instructors = [];
        res.data.data.users.forEach(function (item, index) {
         
          instructors.push({
            value: item.id,
            label: item.firstName + " " + item.lastName
          });
        });
        this.setState({ instructorList: instructors });
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

 

  actionItemClicked = (action, row, event) => {
    event.preventDefault();
    this.setState({
      instructorModal: true,
      modalAction: action,
    });
  };

  dateFormatter = (cell) => {
    if (cell === null) {
      return "";
    }
    return moment(cell).format("MM/DD/YYYY").toString();
  };

  toggleCreateClassModal = () => {
    this.setState({
      createClassModal: !this.state.createClassModal,
    });
  };

  render() {
    const createCustomToolBar = (props) => (
      <Col>
        <StyledTableHeaderRow>
          <Col sm="12" lg="6">
           

            {/* <Button
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => openModal("import-classrooms")}
                    >
                        <i className="fa fa-upload" /> Import classrooms from CSV
                    </Button> */}
          </Col>
          <Col sm="12" lg="6">
            <div className="search">{props.components.searchPanel}</div>
            <div className="search" style={{ color: "maroon" }}>
              <b>Current Sort</b>: column={this.state.sortName}, order=
              {this.state.sortOrder}
            </div>
          </Col>
        </StyledTableHeaderRow>
      </Col>
    );

    const tableOptions = {
      toolBar: createCustomToolBar,
      clearSearch: true,
      sizePerPage: 10,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "30",
          value: 30,
        },
      ],
      onRowClick: (row) => this.onRowClick(row),
      onSortChange: this.onSortChange.bind(this),
    };

    // if (this.props.classrooms === undefined || this.props.classrooms.length < 1) {
    // 	return <h5><b>Classroom is empty !!!</b></h5>;
    // }
    return (
      <Card>
        <CardHeader>Classrooms List
           <ButtonToolbar style={{ float: "right" }}>
          
            <Button
              size="sm"
              outline
              color="primary"
              onClick={() => this.toggleCreateClassModal()}>
              <i className="fa fa-plus" /> 
              <i className="fa fa-graduation-cap" /> 
            </Button>
      <Button size="sm" color="primary" onClick={this.refreshAll}>
              <i className="fa fa-refresh" />
            </Button>
        </ButtonToolbar>
          
        </CardHeader>
        <CardBody>
          <StyledBootstrapTable
            data={this.state.classroomList}
            options={tableOptions}
            hover
            search
            striped
            pagination
            key={"tbl"}>
            <TableHeaderColumn width="8%" isKey dataField="id" dataSort>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              width="20%"
              dataField="className"
              tdStyle={{ whiteSpace: "normal" }}
              dataSort>
              Name
            </TableHeaderColumn>
           
            <TableHeaderColumn
              width="10%"
              dataField="instructors"
              tdStyle={{ whiteSpace: "normal" }}
              editable={{
                type: "select",
                options: { values: this.state.instructorList },
              }}
              dataSort
              dataFormat={this.primaryInstructorFormatter}>
              Primary
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="instructors"
              width="10%"
              dataSort
              dataFormat={this.secondaryInstructorFormatter}>
              Secondaries
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="startDate"
              dataAlign="center"
              dataSort
              width="10%"
              dataFormat={this.dateFormatter}>
              Start
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="endDate"
              dataAlign="center"
              dataSort
              width="10%"
              dataFormat={this.dateFormatter}>
              End
            </TableHeaderColumn>

            <TableHeaderColumn
              dataAlign="center"
              dataField="status"
              dataFormat={this.statusFormatter}
              dataSort
              width="10%">
              Status
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="10%"
              dataField="students"
              dataFormat={this.countFormatter}>
              #Students
            </TableHeaderColumn>
            <TableHeaderColumn
              width="10%"
              dataAlign="center"
              dataField="actions"
              dataFormat={this.menuFormatter}>
              Actions
            </TableHeaderColumn>
          </StyledBootstrapTable>

          <Modal
            isOpen={this.state.instructorModal}
            toggle={this.toggleInstructorModal}
            onClosed={this.refreshAll}
            className={
              this.state.currentRow && this.state.currentRow.className
                ? this.state.currentRow.className
                : "None"
            }>
            <ModalHeader toggle={this.toggleInstructorModal}>
              {this.renderModalHeader()}
            </ModalHeader>
            <ModalBody>{this.renderModalBody()}</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={this.toggleInstructorModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.createClassModal}
            onClosed={this.refreshAll}
            toggle={this.toggle}
            size="lg">
            <ModalHeader toggle={this.toggleCreateClassModal}>
              New classroom for Agile Simulation
            </ModalHeader>
            <ModalBody>
              <CreateAgileClassroom
                instructors={this.state.instructorList}
                organizations={this.state.organizationList}
                toggleClose={this.toggleCreateClassModal}
                classrooms={this.props.classroomList}
                refreshList={this.onRefreshList}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleCreateClassModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    );
  }
}

export default AgileClassroomTable;
