
export default {
	items: [
		{
			name: "Users",
			url: "/",
			icon: "fa fa-users"
		},
		// {
		// 	name: "Instructors",
		// 	url: "/Instructors",
		// 	icon: "fa fa-graduation-cap"
		// },

		{
			name: "Classrooms",
			url: "/classrooms",
			icon: "fa fa-university"
		},

		// {
		// 	name: "Business / University",
		// 	url: "/organizations",
		// 	icon: "fa fa-briefcase"
		// },

		{
			name: "Admin Users",
			url: "/adminUsers",
			icon: "fa fa-users"
		},

		{
			name: "Reports",
			url: "/reports",
			icon: "fa fa-wpforms"
		}
	]
};
