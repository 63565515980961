import React from "react";
import Select from "react-select";
import { ApiHelperFunc } from "../../../../services/AgileApi"

const EditPrimaryInstructorAgile = (props)  => {


	const changePrimaryInstructor =  (selected) => {
	
			const EDIT_PRIMARY_INSTRUCTOR = {
					query: `mutation updatePrimaryInstructor(
							$classroomId: Int!
							$instructorId: Int!
						) {
							updatePrimaryInstructor(
									classroomId:$classroomId,
									instructorId:$instructorId
							) {
								id
							}
						}`,
					variables: {
							classroomId: props.currentClassroom.id,
							instructorId: selected.value
					}
      }
       return ApiHelperFunc(EDIT_PRIMARY_INSTRUCTOR).then((response) => {
      console.log(response);
      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }
    });
		
	};

 const placeholderPrimary = () => {
      // cell is an array of instructors
    // If there's no value, return nothing
    if (!props.currentClassroom.instructors || props.currentClassroom.instructors.length < 1) {
      return "NO PRIMARY INSTRUCTOR";
    }
    let primary = props.currentClassroom.instructors.find(c => c.type === "PRIMARY");
    if (primary) {
      return primary.user.firstName + " " + primary.user.lastName;
    }
    return "NO PRIMARY INSTRUCTOR";

	}

		return (
			<div>
				<Select
					id="instructorSelect"
					options={props.instructors}
					placeholder={placeholderPrimary()}
					onChange={(selected) => {
						changePrimaryInstructor(selected);
					}}
				/>
			</div>
		);
	
}

export default EditPrimaryInstructorAgile
