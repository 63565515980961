import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import Actions from 'redux/Actions';
import { Button, InputGroup, InputGroupAddon, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';

const StyledUserActions = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
	grid-gap: 10px;

	.input-group-addon {
		padding: 0;
	}

	.buttons-row {
		display: flex;
		justify-content: space-between;
	}
`;
// addonType="append"
class UserActions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expirDays: 0,
			expirDate: '',
			email: '', 
			user: this.props.currentUser.user
		};

		this.updateExpirationDateByDate = this.updateExpirationDateByDate.bind(this);
		this.updateExpirationDateByDays = this.updateExpirationDateByDays.bind(this);
		this.changeExpirDays = this.changeExpirDays.bind(this);
		this.changeExpirDate = this.changeExpirDate.bind(this);
		this.changeEmail = this.changeEmail.bind(this);
		this.updateEmail = this.updateEmail.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps !== undefined &&
			nextProps.currentUser !== undefined &&
			nextProps.currentUser.expiratioDate !== undefined
		) {
			const expirDate = moment(nextProps.currentUser.expirationDate).format('MM/DD/YYYY');
			if (nextProps !== this.props) {
				this.setState({
					email: nextProps.currentUser.userEmail,
					expirDate: expirDate
				});
			}
		}
	}
	changeExpirDays = (evt) => {
		this.setState({ expirDays: evt.target.value });
	};
	changeExpirDate = (evt) => {
		this.setState({ expirDate: evt.target.value });
	};

	changeEmail = (evt) => {
		this.setState({ email: evt.target.value });
	};

	resetPassword = () => {
		const payload = {
			username: this.props.currentMembershipUser.UserName
		};
		this.props.resetPassword(payload);
	};
	updateExpirationDateByDays = () => {
		//  const today = moment();
		const expirDate = moment(this.props.currentUser.user.expirationDate);

		const newExpirDt = expirDate.add(this.state.expirDays, 'days');
		//const userId = this.props.currentUser.userId
		const payload = {
			userId: this.props.currentUser.user.userId,
			productId: this.props.currentUser.licenses[0].productId,
			expirationDate: newExpirDt.utc().format()
		};

		this.props.updateExpirationDate(payload).then(() => {
			this.props.fetchUser(payload.userId);
		});
	};

	updateExpirationDateByDate = () => {
		const payload = {
			userId: this.props.currentUser.user.userId,
			productId: this.props.currentUser.licenses[0].productId,
			expirationDate: this.state.expirDate
		};

		this.props.updateExpirationDate(payload).then(() => {
			this.props.fetchUser(payload.userId);
		});
	};

	// updates membership email and mastercontrol user email
	updateEmail = () => {
		const userId = this.props.currentMembershipUser.UserId;

		const username = this.props.currentMembershipUser.UserName;

		const newEmail = this.email.value;
	
		/* change membership email - main  email */
		const payload1 = {
			username: username,
			newEmail: newEmail
    };

		this.props.updateEmail(payload1)
		//membership email

		/* change master control user email - mostly for display */

		const payload2 = {
			userId: userId,
			email: this.state.email
		};
		this.props.updateUserEmail(payload2)
		// master control user email
	};

	render() {
		return (
			<StyledUserActions>
				<h3>Actions</h3>
				<div className="buttons-row">
					<Button color="primary" onClick={this.resetPassword}>
						Reset User Password
					</Button>
					{/* <Button>Unlock User / Send Password</Button> */}
				</div>
				<div className="buttons-row">
					<Button color="primary" onClick={this.addTestUserAccount}>
						Create Test User Account
					</Button>
				</div>


				<Label>Set new expiration date by day or by adding days</Label>
				<InputGroup>
					<Input
						type="date"
						value={this.state.expirDate}
						onChange={(evt) => this.changeExpirDate(evt)}
						placeholder="mm/dd/yyyy"
					/>
					<InputGroupAddon addonType="prepend">
						<Button color="primary" onClick={this.updateExpirationDateByDate}>
							Change Expiration Date
						</Button>
					</InputGroupAddon>
				</InputGroup>
				<InputGroup>
					<Input
						type="number"
						value={this.state.expirDays}
						onChange={(evt) => this.changeExpirDays(evt)}
						placeholder="days from today"
						min = "0"
					/>
					<InputGroupAddon addonType="prepend">
						<Button color="primary" onClick={this.updateExpirationDateByDays}>
							Extend License
						</Button>
					</InputGroupAddon>
				</InputGroup>
				<Label>Update email</Label>

				<InputGroup>
					<Input
						type="email"
						id="userEmail"
						innerRef={(em) => (this.email = em)}
						value={this.state.email || ''}
						onChange={(evt) => this.changeEmail(evt)}
					/>
					<InputGroupAddon addonType="prepend">
						<Button color="primary" onClick={this.updateEmail}>
							Update email address
						</Button>
					</InputGroupAddon>
				</InputGroup>
			</StyledUserActions>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentMembershipUser: (state) => get(state, 'membership.currentMembershipUser'),
	currentUser: (state) => get(state, 'users.currentUser')
});

const mapDispatchToProps = (dispatch) => ({
	
	updateUserEmail: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.updateUserEmailRequest(payload, resolve, reject))),
	updateEmail: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.updateEmailRequest(payload, resolve, reject))),
	updateExpirationDate: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.updateExpirationRequest(payload, resolve, reject))),
	resetPassword: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.adminResetPasswordRequest(payload, resolve, reject))),
	fetchUser: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchUserRequest(userId, resolve, reject)))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
